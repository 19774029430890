import {
  BicycleSvg,
  Box,
  Button,
  ButtonVariant,
  Card,
  CardContent,
  CardHeader,
  EFont,
  ESpacing,
  ETripType,
  Font,
  IBEPax,
  Journey,
  SimCardIcon,
  SvgIcon,
  UFirstIcon,
  useMediaQueries,
} from "@hkexpressairwayslimited/ui";
import { Stack, styled } from "@mui/material";
import { add } from "lib/common/helper.ts";
import { getTripTitle } from "lib/features/flight-book/helper.ts";
import { useTransContent } from "modules/common/trans-content/transContent";
import { EArItemType, EOtherArCode } from "../d";
export * from "./otherSection.tsx";
export type OnSportSelectedData = { tripId: string; journeyKey: string; paxId: string; added: boolean; code: string };
export type OnSportCloseData = { tripId: string; code: string };
export type SportCloseEvent = (data: OnSportCloseData) => void;
export type OnUFirstSelectedData = {
  tripId: string;
  journeyKey: string;
  added: boolean;
  passengers: IBEPax[];
  code: string;
};
export type OnUFirstCloseData = {
  tripId: string;
  code: string;
};
const StyleFontPrice = styled(Font)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {},
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "18px",
    maxWidth: "85px",
    "& > :first-of-type > :nth-of-type(2)": {
      width: "70%",
    },
  },
  [theme.breakpoints.down("md")]: {},
}));
export type OnSimCardSelectedData = { tripId: string; paxId: string; added: boolean; code: string };
export type OnSimCardCloseData = { tripId: string; code: string };
export const otherArItemConf: { [k in EOtherArCode]: { avatar: JSX.Element; title: string; des: string } } = {
  [EOtherArCode.SPORT_EQUIPMENT]: {
    avatar: <BicycleSvg />,
    title: "web.flightBooking.otherSection.sportEquipment.title",
    des: "web.flightBooking.otherSection.sportEquipment.desc",
  },
  [EOtherArCode.U_FIRST]: {
    avatar: <UFirstIcon />,
    title: "web.flightBooking.otherSection.uFirst.title",
    des: "web.flightBooking.otherSection.uFirst.desc",
  },
  [EOtherArCode.SIM_CARD]: {
    avatar: <SimCardIcon />,
    title: "web.flightBooking.otherSection.simCardA.title",
    des: "web.flightBooking.otherSection.simCardA.content",
  },
  [EOtherArCode.SIM_CARD_B]: {
    avatar: <SimCardIcon />,
    title: "web.flightBooking.otherSection.simCardB.title",
    des: "web.flightBooking.otherSection.simCardB.content",
  },
};

export function Summary({ children }: { children: JSX.Element }) {
  return (
    <Card bodC='active' withBGC='tertiary'>
      <CardContent>{children}</CardContent>
    </Card>
  );
}

export function SelectItem({
  code,
  children,
  onClick,
  price,
  totalCost,
  summary,
  isAllPurchased,
}: {
  code: EOtherArCode;
  onClick: () => void;
  children: JSX.Element;
  totalCost: string | JSX.Element;
  summary?: JSX.Element;
  price?: string | JSX.Element;
  isAllPurchased?: boolean;
}) {
  const { avatar, title, des } = otherArItemConf[code];
  const { t } = useTransContent();
  const { isMobile } = useMediaQueries();

  return (
    <>
      <Card sx={{ height: "100%" }} id={`${code}_section_title`}>
        <CardHeader
          sx={{
            alignItems: "normal",
          }}
          avatar={
            <SvgIcon
              sx={{ color: "purple.default", width: isMobile ? "24px" : "32px", height: isMobile ? "24px" : "32px" }}
            >
              {avatar}
            </SvgIcon>
          }
          title={
            <Font
              variant={isMobile ? EFont.p1 : EFont.h3}
              fontWeight='fontWeightBold'
              sx={{
                "@media (max-width: 900px)": { minHeight: "32px" },
                "@media (min-width: 1024px)": { minHeight: "95px" },
              }}
            >
              {t(title)}
            </Font>
          }
        ></CardHeader>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          {summary ? summary : <Font sx={{ mb: ESpacing._sm, ml: [0, 0, ESpacing._3xs] }}>{t(des)}</Font>}
          <Card withBGC borderless sx={{ marginTop: ESpacing._s }}>
            <CardContent>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Box sx={{ flexGrow: 1 }}>
                  {summary ? (
                    <Stack direction='row' alignItems='center' columnGap={ESpacing._2xs}>
                      <Font variant={EFont.p2}>{t("web.addExtras.baggage.subtotal")}</Font>
                      <Font variant={EFont.h3} fontWeight='600'>
                        {totalCost}
                      </Font>
                    </Stack>
                  ) : (
                    <Stack direction='row' alignItems='center' columnGap={ESpacing._2xs}>
                      <StyleFontPrice color='purple.default' variant={EFont.h3} fontWeight='600'>
                        {price}
                      </StyleFontPrice>
                      <Font variant={EFont.p2}>{t("web.flightBooking.otherSection.prePax")}</Font>
                    </Stack>
                  )}
                </Box>

                <Button
                  style={{ maxHeight: "40px" }}
                  disabled={isAllPurchased}
                  onClick={() => onClick && onClick()}
                  variant={ButtonVariant.Secondary}
                  aria-labelledby={`${code}_section_title`}
                >
                  <Font whiteSpace={"nowrap"}>
                    {summary
                      ? t("web.flightBooking.otherSection.button.edit")
                      : t("web.flightBooking.otherSection.button.add")}
                  </Font>
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
      {children}
    </>
  );
}

export function formatJourneySelectedArItemSummary(journeys: Journey[], type: EArItemType, tripType: ETripType) {
  return journeys.map((e, idx) => ({
    label: getTripTitle(e?.index ?? idx, tripType),
    amount: e.arItems.selected
      .filter((e) => e.type === type)
      .reduce((a, c) => {
        if (c.byPax) {
          return add(
            a,
            c.byPax?.reduce((a1, c1) => add(a1, c1.amount), 0)
          );
        }
        return a;
      }, 0),
  }));
}

export function formatJourneySelectedAndPurchasedArItemSummary(
  journeys: Journey[],
  type: EArItemType,
  tripType: ETripType,
  passengers: IBEPax[]
) {
  const passengerId = passengers?.reduce((a: string[], i) => [...a, i.id], []);
  return journeys.map((e, idx) => ({
    label: getTripTitle(e?.index ?? idx, tripType),
    amount: add(
      e.arItems.selected
        .filter((e) => e.type === type)
        .reduce((a, c) => {
          if (c.byPax) {
            return add(
              a,
              c.byPax?.reduce((a1, c1) => add(a1, c1.amount), 0)
            );
          }
          return a;
        }, 0),
      e.arItems.purchased
        .filter((e) => e.type === type)
        .reduce((a, c) => {
          if (c.byPax) {
            return add(
              a,
              c.byPax?.reduce((a1, c1) => (passengerId?.includes(c1.paxId) ? add(a1, c1.amount) : a1), 0)
            );
          }
          return a;
        }, 0)
    ),
  }));
}

export function formatJourneySelectedAndTransferred(journeys: Journey[], type: EArItemType, tripType: ETripType) {
  return journeys.map((e, idx) => ({
    label: getTripTitle(e?.index ?? idx, tripType),
    amount: add(
      e.arItems.selected
        .filter((e) => e.type === type)
        .reduce((a, c) => {
          if (c.byPax) {
            return add(
              a,
              c.byPax?.reduce((a1, c1) => add(a1, c1.amount), 0)
            );
          }
          return a;
        }, 0),
      e.arItems.purchased
        .filter((e) => e.type === type)
        .reduce((a, c) => {
          if (c.byPax) {
            return add(
              a,
              c.byPax?.reduce((a1, c1) => add(a1, c1.amount), 0)
            );
          }
          return a;
        }, 0)
    ),
  }));
}
