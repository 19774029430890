"use client";
import { Button, ButtonVariant, Font, Image, Modal, ModalHandle } from "@hkexpressairwayslimited/ui";
import { Stack } from "@mui/material";
import { ErrorType } from "lib/features/flight-book/payment/definition";
import { useTransContent } from "modules/common/trans-content/transContent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanErrorModalDetail, selectErrorModalDetail } from "store/sessionStorage/slices/errorModalDetail";

export const ConfirmErrorModal = () => {
  const { t } = useTransContent();
  const { desc, title, image, ctaText, onClose, onCloseTextCb, onConfirm, type, data, noCrossIcon, closeText } =
    useSelector(selectErrorModalDetail) ?? {};
  const [ref, setFef] = useState<ModalHandle | null>();
  const dispatch = useDispatch();
  useEffect(() => {
    if (desc && ref) {
      ref.open();
    }
  }, [desc, ref]);
  return (
    type === ErrorType.C &&
    desc &&
    title && (
      <Modal
        disabledBackDropClose={true}
        noCrossIcon={noCrossIcon ?? true}
        title={t(title, data?.tReplaceData?.title)}
        onClose={() => {
          ref?.close();
          dispatch(cleanErrorModalDetail());
          onClose && onClose(data);
        }}
        ref={setFef}
      >
        <Stack rowGap={2} direction='column'>
          {image && <Image src={(t(image) as string) ?? ""} width='390' height='125' alt='' />}
          <Font>{t(desc, data?.tReplaceData?.desc)}</Font>
          {ctaText && (
            <Button
              fullWidth
              onClick={() => {
                ref?.close();
                dispatch(cleanErrorModalDetail());
                onConfirm && onConfirm(data);
              }}
            >
              {t(ctaText)}
            </Button>
          )}
          {closeText && (
            <Button
              variant={ButtonVariant.Text}
              onClick={() => {
                ref?.close();
                // dispatch(cleanErrorModalDetail());
                onCloseTextCb && onCloseTextCb(); // separate from onClose with onCloseTextCb as onClose will be triggered multiple times
              }}
            >
              {t(closeText)}
            </Button>
          )}
          {data?.trace_id && <input type='hidden' id='api_response_trace_id' value={data.trace_id} />}
        </Stack>
      </Modal>
    )
  );
};
