import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ErrorMessageDetail } from "lib/features/flight-book/payment/definition";
import { RootState } from "store";

const initialState: Partial<ErrorMessageDetail> = {};

export const errorModalDetailSlice = createSlice({
  name: "errorModalDetail",
  initialState,
  reducers: {
    storeErrorModalDetail: (state, action: PayloadAction<ErrorMessageDetail>) => {
      Object.assign(state, action.payload);
    },
    cleanErrorModalDetail: (state) => {
      Object.assign(state, {
        type: undefined,
        title: undefined,
        desc: undefined,
        detailsElm: undefined,
        onClose: undefined,
        onConfirm: undefined,
        data: undefined,
        ctaText: undefined,
        closeText: undefined,
        fn: undefined,
        image: undefined,
      });
    },
  },
  selectors: {
    selectErrorModalDetail: (state): Partial<ErrorMessageDetail> | undefined => state,
  },
});

export const { storeErrorModalDetail, cleanErrorModalDetail } = errorModalDetailSlice.actions;
export default errorModalDetailSlice.reducer;
export const { selectErrorModalDetail } = errorModalDetailSlice.getSelectors((state: RootState) => {
  return state.session.errorModalDetail;
});
