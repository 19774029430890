import { createAction } from "@reduxjs/toolkit";
import { ErrorModel } from "modules/common/common/types/errorType";
import config from "../package.json";
import { MlcTokenPayload, TokenPayload } from "../types/authType";

/** Do Login Actions */
export const LOGIN_START = `${config.name}/LOGIN_START`;
export const doLogin = createAction(LOGIN_START);

export const LOGIN_FAILURE = `${config.name}/LOGIN_FAILURE`;
export const loginFailure = createAction<{ error: ErrorModel }>(LOGIN_FAILURE);

/** Fetch Token Actions */
export const FETCH_TOKEN_START = `${config.name}/FETCH_TOKEN_START`;
export const fetchToken = createAction<{
  code: string;
}>(FETCH_TOKEN_START);

export const FETCH_TOKEN_SUCCESS = `${config.name}/FETCH_TOKEN_SUCCESS`;
export const fetchTokenSuccess = createAction<TokenPayload>(FETCH_TOKEN_SUCCESS);

export const FETCH_TOKEN_FAILURE = `${config.name}/FETCH_TOKEN_FAILURE`;
export const fetchTokenFailure = createAction<{ error: ErrorModel }>(FETCH_TOKEN_FAILURE);

export const FETCH_MLC_TOKEN_SUCCESS = `${config.name}/FETCH_MLC_TOKEN_SUCCESS`;
export const fetchMlcTokenSuccess = createAction<MlcTokenPayload>(FETCH_MLC_TOKEN_SUCCESS);

/** Refresh token actions */
export const REFRESH_TOKEN_START = `${config.name}/REFRESH_TOKEN_START`;
export const refreshToken = createAction<{
  backgroundMode?: boolean;
}>(REFRESH_TOKEN_START);

export const REFRESH_TOKEN_SUCCESS = `${config.name}/REFRESH_TOKEN_SUCCESS`;
export const refreshTokenSuccess = createAction<TokenPayload>(REFRESH_TOKEN_SUCCESS);

export const REFRESH_TOKEN_FAILURE = `${config.name}/REFRESH_TOKEN_FAILURE`;
export const refreshTokenFailure = createAction<{
  error: ErrorModel;
}>(REFRESH_TOKEN_FAILURE);

export const REFRESH_TOKEN_END = `${config.name}/REFRESH_TOKEN_END`;
export const refreshTokenEnd = createAction<undefined>(REFRESH_TOKEN_END);

export const REFRESH_TOKEN_NOT_EXIST = `${config.name}/REFRESH_TOKEN_NOT_EXIST`;
export const refreshTokenNotExist = createAction(REFRESH_TOKEN_NOT_EXIST);

/** Logout actions */
export const LOGOUT = `${config.name}/LOGOUT`;
export const logout = createAction(LOGOUT);
