import {
  AdultIcon,
  ArItem,
  ArItems,
  BundleOffer,
  Card,
  CardContent,
  ChildIcon,
  EPaxType,
  ESpacing,
  Font,
  Pax,
} from "@hkexpressairwayslimited/ui";
import { Stack, SvgIcon } from "@mui/material";
import { add } from "lib/common/helper";
import { cloneDeep, isEmpty } from "lodash";
import { useTransContent } from "modules/common/trans-content/transContent";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectAddExtrasPageMetaData } from "store/sessionStorage/slices/flightBookingSlice";
import { ArItemAmount, ArItemsAmount, EArItemType, EBaggageArCode, OnBaggageSelectedData, SelectableArItem } from "..";
import { BaggageItem } from "./BaggageItem";

export function PassengerSection({
  passenger,
  arItems,
  onBaggageSelect,
  journeyKey,
  tripId,
  bundleFare,
  noPreSelection = true,
  preSelectionDisabled = false,
  selectedArItem = [],
  checkBundle,
}: {
  bundleFare: BundleOffer[];
  passenger: Pax;
  arItems: ArItems;
  journeyKey: string;
  tripId: string;
  noPreSelection?: boolean;
  preSelectionDisabled?: boolean;
  checkBundle?: boolean;
  selectedArItem: ArItem[];
  onBaggageSelect: (data: OnBaggageSelectedData) => void;
}) {
  const { t } = useTransContent();

  const sortBaggageCode = (a: SelectableArItem, b: SelectableArItem) => {
    if (a.arMetaData?.included) {
      return -1;
    } else if (b.arMetaData?.included) {
      return 1;
    }

    if (a.code > b.code) {
      return 1;
    }
    if (a.code < b.code) {
      return -1;
    }
    return 0;
  };

  const includedArItem = useMemo(() => {
    const IncludedBaggageBundle = bundleFare[0].bundles?.find((e) => e.ssr_code === "PC20" || e.ssr_code === "PC32");
    return IncludedBaggageBundle && arItems.available.find((e) => e.code === IncludedBaggageBundle.ssr_code);
  }, [arItems, bundleFare]);

  // const includedArItem = IncludedBaggageBundle && arItems.find((e) => e.code === IncludedBaggageBundle.ssr_code);
  const userSelected = useMemo(() => {
    const userSelected: ArItemsAmount = {};
    selectedArItem &&
      selectedArItem.forEach((e) => {
        if (e.type === EArItemType.BAGGAGE) {
          e.byPax?.forEach((n) => {
            if (userSelected[n.paxId]) {
              userSelected[n.paxId][e.code] = n.amount;
            } else {
              userSelected[n.paxId] = { [e.code]: n.amount };
            }
          });
        }
      });
    return userSelected;
  }, [selectedArItem]);

  const userPurchased = useMemo(() => {
    const userPurchased: ArItemsAmount = {};
    arItems.purchased.forEach((e) => {
      if (e.type === EArItemType.BAGGAGE && arItems.available.some((n) => n.code === e.code)) {
        e.byPax?.forEach((n) => {
          if (userPurchased[n.paxId]) {
            userPurchased[n.paxId][e.code] = n.amount;
          } else {
            userPurchased[n.paxId] = { [e.code]: n.amount };
          }
        });
      }
    });
    return userPurchased;
  }, [arItems.available, arItems.purchased]);

  const userPurchasedNotAvailable = useMemo(() => {
    const userPurchasedNotAvailable: ArItemsAmount = {};
    const purchasedNotAvailableBaggage = arItems.purchased.filter(
      (e) => e.type === EArItemType.BAGGAGE && !arItems.available.some((n) => n.code === e.code)
    );

    purchasedNotAvailableBaggage.forEach((e) => {
      e.byPax?.forEach((n) => {
        if (userPurchasedNotAvailable[n.paxId]) {
          userPurchasedNotAvailable[n.paxId][e.code] = n.amount;
        } else {
          userPurchasedNotAvailable[n.paxId] = { [e.code]: n.amount };
        }
      });
    });
    return userPurchasedNotAvailable;
  }, [arItems.available, arItems.purchased]);

  const formatBaggageOptions = useCallback(
    (
      arItems: ArItem[],
      purchasedNotAvailableBaggage: ArItem[],
      userPurchasedNotAvailable: ArItemAmount,
      currentUserPurchased: ArItemAmount,
      currentUserSelected: ArItemAmount
    ): SelectableArItem[] => {
      let idx = 0;
      const totalPurchased = { ...currentUserPurchased, ...userPurchasedNotAvailable };
      const emptyPurchased = isEmpty(totalPurchased);
      const includedOptions: SelectableArItem | undefined = includedArItem
        ? {
            ...includedArItem,
            selected: true,
            purchased: false,
            id: `${journeyKey}_${passenger.id}_${includedArItem.code}_${EArItemType.BAGGAGE}`,
            arMetaData: { included: true },
            availability: includedArItem.availability === null ? Infinity : undefined,
          }
        : {
            selected: currentUserSelected[EBaggageArCode._0KG] > 0 || isEmpty(currentUserSelected),
            purchased: false,
            type: EArItemType.BAGGAGE,
            code: EBaggageArCode._0KG,
            price: 0,
            availability: Infinity,
            id: `${journeyKey}_${passenger.id}_${EBaggageArCode._0KG}_${EArItemType.BAGGAGE}`,
          };
      const totalPurchasedAmount = !emptyPurchased
        ? add(
            Object.keys(currentUserPurchased).reduce((total, item) => add(total, currentUserPurchased[item]), 0),
            Object.keys(userPurchasedNotAvailable).reduce(
              (total, item) => add(total, userPurchasedNotAvailable[item]),
              0
            )
          )
        : 0;

      const updatedOptions = arItems
        .filter((e) => e?.type === EArItemType.BAGGAGE)
        .reduce<SelectableArItem[]>(
          (a, c) => {
            const purchasedAmount = currentUserPurchased[c.code] ?? 0;
            const selectedAmount = currentUserSelected[c.code] ?? 0;
            if (purchasedAmount > 1) {
              for (let i = 0; i < purchasedAmount; i++) {
                a.push({
                  ...c,
                  selected: false,
                  purchased: true,
                  id: `${journeyKey}_${passenger.id}_${c.code}-${idx}`,
                  availability: c.availability === null ? Infinity : undefined,
                });
                idx++;
              }
            } else if (emptyPurchased || purchasedAmount === 1) {
              a.push({
                ...c,
                selected: selectedAmount > 0 && purchasedAmount === 0,
                purchased: purchasedAmount > 0,
                id: `${journeyKey}_${passenger.id}_${c.code}-${idx}`,
                availability: c.availability === null ? Infinity : undefined,
              });
            }
            idx++;

            if (includedOptions?.code === EBaggageArCode._0KG && !(totalPurchasedAmount > 1)) {
              a.push({
                ...c,
                selected:
                  add(selectedAmount, purchasedAmount) >
                  (!emptyPurchased && selectedAmount === 1 && purchasedAmount === 0 ? 0 : 1),
                purchased: purchasedAmount > 1,
                id: `${journeyKey}_${passenger.id}_${c.code}-${idx}`,
                availability: c.availability === null ? Infinity : undefined,
              });
              idx++;
            }
            return a;
          },
          noPreSelection ? [] : [includedOptions]
        );
      if (purchasedNotAvailableBaggage.length > 0) {
        purchasedNotAvailableBaggage.forEach((e) => {
          const targetBaggage = e.byPax ? e.byPax.find((n) => n.paxId === passenger.id) : undefined;
          if (targetBaggage) {
            for (let i = 0; i < targetBaggage.amount; i++) {
              updatedOptions.push({
                ...e,
                selected: false,
                purchased: true,
                id: `${journeyKey}_${passenger.id}_${e.code}-${idx}`,
                availability: e.availability === null ? Infinity : undefined,
              });
              idx++;
            }
          }
        });
      }
      return emptyPurchased ? updatedOptions : updatedOptions.filter((e) => e.code !== EBaggageArCode._0KG);
    },
    [includedArItem, journeyKey, passenger, noPreSelection]
  );
  const { baggageOption, userSelectedDisabled, baggageAvailableConf } = useMemo(() => {
    const currentUserSelected = userSelected[passenger.id] ?? {};
    const currentUserPurchased = userPurchased[passenger.id] ?? {};
    const currentUserPurchasedNotAvailable = userPurchasedNotAvailable[passenger.id] ?? {};
    const baggageAvailableConf: ArItemAmount = {};

    const purchasedNotAvailableBaggage = arItems.purchased.filter(
      (e) => e.type === EArItemType.BAGGAGE && !arItems.available.some((n) => n.code === e.code)
    );

    const baggageOption = formatBaggageOptions(
      arItems.available,
      purchasedNotAvailableBaggage,
      currentUserPurchasedNotAvailable,
      currentUserPurchased,
      currentUserSelected
    ).sort(sortBaggageCode);

    const userSelectedDisabled =
      add(
        Object.keys(currentUserSelected).reduce((a, c) => add(a, currentUserSelected[c] ?? 0), 0),
        includedArItem ? 1 : 0,
        isEmpty(currentUserPurchased)
          ? 0
          : Object.keys(currentUserPurchased).reduce((a, c) => add(a, currentUserPurchased[c] ?? 0), 0)
      ) > 1;

    return { baggageOption, userSelectedDisabled, baggageAvailableConf };
  }, [
    userSelected,
    passenger.id,
    userPurchased,
    userPurchasedNotAvailable,
    arItems.purchased,
    arItems.available,
    formatBaggageOptions,
    includedArItem,
  ]);

  const baggageSelectDisabled = useCallback(
    (code: string) => {
      const baggageAvailable = baggageAvailableConf[code];
      if (userSelected && baggageAvailable) {
        return (
          Object.keys(userSelected).reduce((a, c) => {
            if (userSelected[c]) {
              return add(a, userSelected[c][code] ?? 0);
            }
            return a;
          }, 0) >= baggageAvailable
        );
      }
      return false;
    },
    [baggageAvailableConf, userSelected]
  );
  const storedSelectedBaggage =
    useSelector(selectAddExtrasPageMetaData)?.baggageSelected[
      `${tripId ? `${tripId}_` : ""}${journeyKey}_${passenger.id}`
    ];
  const [renderedOptions, setRenderedOptions] = useState(storedSelectedBaggage ?? baggageOption);

  const handleBaggageSelect = useCallback(
    (arItem: SelectableArItem, active = false) => {
      const updatedOptions = cloneDeep(renderedOptions);
      updatedOptions.forEach((e) => {
        if (arItem.code !== EBaggageArCode._0KG && e.code === EBaggageArCode._0KG && !active) {
          e.selected = false;
        } else if (arItem.code === EBaggageArCode._0KG && e.code !== EBaggageArCode._0KG && !active) {
          e.selected = false;
        }
        if (e.id === arItem.id) {
          e.selected = !active;
        }
      });
      setRenderedOptions(updatedOptions);
      onBaggageSelect({
        tripId,
        paxId: passenger.id,
        selectedBaggage: arItem,
        active,
        journeyKey,
        renderOption: updatedOptions,
      });
    },
    [journeyKey, onBaggageSelect, passenger.id, renderedOptions, tripId]
  );
  useEffect(() => {
    const currentUserSelected = userSelected[passenger.id] ?? {};
    const currentUserPurchased = userPurchased[passenger.id] ?? {};
    const currentUserPurchasedNotAvailable = userPurchasedNotAvailable[passenger.id] ?? {};
    if (
      !includedArItem &&
      (currentUserSelected[EBaggageArCode._0KG] > 0 || isEmpty(currentUserSelected)) &&
      isEmpty(currentUserPurchased) &&
      isEmpty(currentUserPurchasedNotAvailable)
    ) {
      onBaggageSelect({
        tripId,
        paxId: passenger.id,
        selectedBaggage: renderedOptions[0],
        active: false,
        journeyKey,
        renderOption: renderedOptions,
      });
    }
  }, []);
  const tag = t("web.flightBook.popular.baggage.code");
  const processedCodes = new Set();

  return (
    <Card withBGC sx={{ minHeight: checkBundle ? "312px" : undefined }}>
      <CardContent>
        <Stack direction={["column", "column", "row"]} columnGap={ESpacing._2xs}>
          <Stack direction='row' columnGap={ESpacing._2xs}>
            <SvgIcon>{passenger.paxType === EPaxType.Adult ? <AdultIcon /> : <ChildIcon />}</SvgIcon>
            <Font
              fontWeight='fontWeightBold'
              display={["flex", "flex", "none"]}
            >{`${passenger.courtesy} ${passenger.surname} ${passenger.givenName}`}</Font>
          </Stack>
          <Stack rowGap={ESpacing._2xs}>
            <Font
              fontWeight='fontWeightBold'
              display={["none", "none", "flex"]}
            >{`${passenger.courtesy} ${passenger.surname} ${passenger.givenName}`}</Font>
            <Stack
              direction='row'
              rowGap={ESpacing._sm}
              columnGap={ESpacing._2xs}
              sx={{
                flexWrap: "wrap",
                mt: ESpacing._xs,
              }}
            >
              {renderedOptions.map((e, idx) => {
                const isFirstOccurrence = !processedCodes.has(e.code);
                if (isFirstOccurrence) {
                  processedCodes.add(e.code);
                }

                return (
                  <BaggageItem
                    onBaggageSelect={handleBaggageSelect}
                    key={e.code + idx}
                    arItem={e}
                    active={e.selected ?? false}
                    purchased={!!e.purchased}
                    disabled={!!e.purchased || userSelectedDisabled || baggageSelectDisabled(e.code)}
                    displayTag={
                      (t(`${e.code}.title.short`) as string).includes(tag as string) && !e.arMetaData?.included
                    }
                  />
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
