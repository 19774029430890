import { ETripType, Journey } from "@hkexpressairwayslimited/ui";

export function getTripType(journeys: Journey[] = []) {
  return journeys.length === 2
    ? journeys[0].destination === journeys[1].origin && journeys[0].origin === journeys[1].destination
      ? ETripType.roundTrip
      : ETripType.multiCity
    : ETripType.oneWay;
}

export function getTripTitle(index: number, tripType?: ETripType): string {
  if (!tripType) {
    return "";
  }
  if (tripType !== ETripType.multiCity) {
    if (index % 2 === 0) {
      return "Depart";
    } else {
      return "Return";
    }
  }
  return `Journey${index % 2}`;
}
