"use client";
import {
  Box,
  Button,
  EPixel,
  ESpacing,
  Font,
  LoadingBackdrop,
  Modal,
  ModalHandle,
  SignInAndForgetPwd,
  clearAuthTokens,
} from "@hkexpressairwayslimited/ui";
import { Stack } from "@mui/material";
import { getAnalyticPage, getAnalyticPath, pushAdobeDataLayer } from "analytic";
import { addQueryParam, memberAuthEndPoints, parseJwt, postApi, postMessageToMobile } from "api";
import { agentLogout } from "lib/features/b2b/payment/service";
import { ErrorType } from "lib/features/flight-book/payment/definition";
import { getLangLocale } from "modules/common/common/utils/i18n-utils";
import useCountryCodeOptions from "modules/common/hooks/useCountryCode";
import { useTransContent } from "modules/common/trans-content/transContent";
import { fetchTokenSuccess } from "modules/features/auth/actions/authAction";
import { signInApple, signInCx } from "modules/features/auth/service";
import { useParams, useRouter } from "next/navigation";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouterInstance } from "router/router-utils";
import { clearAgentToken, selectIsLogin } from "store/sessionStorage/slices/b2bSlice";
import { storeErrorModalDetail } from "store/sessionStorage/slices/errorModalDetail";

export enum SignInFromType {
  IBE = "IBE",
  MMB = "MMB",
  HEADER = "HEADER",
}
interface CustomPageProps {
  onSignInSucceed?: () => void;
  onForgetPasswordSucceed?: () => void;
  isDisplayClose?: boolean;
  onCloseLoginNow?: () => void;
  onCXMemberClick?: () => void;
  onCxRedirectCallback?: () => void;
  from?: SignInFromType;
  cxAuthenticationRedirectLink?: string;
}
export default function SignInPage({
  onSignInSucceed,
  onForgetPasswordSucceed,
  isDisplayClose,
  onCloseLoginNow,
  onCXMemberClick,
  onCxRedirectCallback,
  from,
  cxAuthenticationRedirectLink,
}: CustomPageProps) {
  const dispatch = useDispatch();
  const b2bIsLogin = useSelector(selectIsLogin);
  const lang = useParams().lang || "";
  // const { isMobile, isTablet, isDesktop } = useMediaQueries();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [signInErrorMsg, setSignInErrorMsg] = useState<string | JSX.Element | JSX.Element[]>();
  const router = useRouter();
  const [forgetPwdErrorPwdMsgMsg, setForgetPwdErrorPwdMsgMsg] = useState<string | JSX.Element | JSX.Element[]>();
  const { t } = useTransContent();
  const [forgetPwdSucceedModal, setForgetPwdSucceedModal] = useState<ModalHandle | null>();

  const [notAuthorizedModalHandle, setNotAuthorizedModalHandle] = useState<ModalHandle | null>();

  useEffect(() => {
    if (!onSignInSucceed) {
      document.title = t("web.B2B.Login.sign.button") as string;
    }
  }, [onSignInSucceed, t]);

  const showTechnicalErrorMsgSignIn = useCallback(
    (msg: string = "web.member.signIn.messageBox.technicalError") => {
      setSignInErrorMsg(t(msg));
    },
    [t]
  );
  const showTechnicalErrorMsgForgetPwd = useCallback(() => {
    setForgetPwdErrorPwdMsgMsg(t("web.member.signIn.messageBox.technicalError"));
  }, [t]);

  const onSignIn = useCallback(
    async (formValues: any) => {
      if (formValues && formValues.data) {
        setIsLoading(true);
        setSignInErrorMsg("");
        clearAuthTokens();
        const {
          email,
          password,
          mobile: { countryCode, mobileNumber },
          method,
        } = formValues.data;
        if (
          !method ||
          (method === "email" && !email) ||
          (method === "phone" && (!countryCode || !mobileNumber)) ||
          !password
        ) {
          // console.error("Missing Required Fields");
          setIsLoading(false);
          showTechnicalErrorMsgSignIn();
          return false;
        }
        try {
          const response = await postApi(
            memberAuthEndPoints.signIn,
            {
              username: method === "email" ? email : `+${countryCode}${mobileNumber}`,
              password: password,
              login_type: method === "email" ? "EMAIL" : "PHONE",
            },
            undefined,
            {
              credentials: true,
            },
            true,
            false
          );
          if (response.metadata.status_code === 200) {
            const { access_token, refresh_token, id_token, mlc_access_token, complete_profile_token } = response;

            try {
              // adobe analytic mmb
              const { pathName } = getAnalyticPath(window?.location.pathname);
              pushAdobeDataLayer({
                event: "login_success",
                page: getAnalyticPage({
                  name:
                    from === SignInFromType.IBE
                      ? "booking::login success page"
                      : from === SignInFromType.MMB
                        ? "mmb::login page"
                        : pathName,
                }),
                interaction: {
                  name: "login::success",
                },
                member: {
                  id: parseJwt(access_token).username || "",
                  login_type: method === "email" ? "email" : method === "phone" ? "mobile" : "",
                },
              });
            } catch (err) {
              // console.error(err);
            }

            if (complete_profile_token) {
              const url = `/member/complete-profile?complete_profile_token=${complete_profile_token}&fromSignIn=true`;
              const urlParams = new URLSearchParams(window.location.search);
              const device = urlParams.get("device") || "web";
              const newUrl = addQueryParam(url, "device", device);
              router.push(newUrl);
              return;
            }
            // if (response.redirect_url) {
            //   signInCx(undefined, true);
            //   setIsLoading(false);
            //   return;
            // }
            if (response.redirect_url && from !== SignInFromType.HEADER) {
              holdThisPage();
            }
            dispatch(
              fetchTokenSuccess({
                accessToken: access_token || "",
                refreshToken: refresh_token || "",
                idToken: id_token || "",
                mlcAccessToken: mlc_access_token || "",
              })
            );
            if (b2bIsLogin) {
              agentLogout();
              dispatch(clearAgentToken());
            }

            if (window.ReactNativeWebView) {
              postMessageToMobile({
                accessToken: access_token || "",
                idToken: id_token || "",
                refreshToken: refresh_token || "",
                mlcAccessToken: mlc_access_token || "",
              });
              // return;
            }
            if (response.redirect_url) {
              dispatch(
                storeErrorModalDetail({
                  type: ErrorType.C,
                  title: t(`web.myuo.reAuth.title`) as string,
                  desc: t(`web.myuo.reAuth.desc`) as string,
                  ctaText: t("web.myuo.reAuth.cta") as string,
                  closeText: t("web.myuo.reAuth.notNow") as string,
                  noCrossIcon: false,
                  onConfirm: () => {
                    const searchParams = cxAuthenticationRedirectLink
                      ? new URLSearchParams({
                          cxAuthenticationRedirectLink,
                        })
                      : null;
                    const pushUrl = searchParams ? `/member/link-cx?${searchParams.toString()}` : `/member/link-cx`;
                    const urlParams = new URLSearchParams(window.location.search);
                    const device = urlParams.get("device") || "web";
                    const newUrl = addQueryParam(pushUrl, "device", device);
                    router.push(newUrl);
                  },
                  onCloseTextCb: () => {
                    // const urlParams = new URLSearchParams(window.location.search);
                    // const device = urlParams.get("device") || "web";
                    // const newUrl = addQueryParam(`/member/link-cx`, "device", device);
                    // router.push(newUrl);
                    if (onSignInSucceed) {
                      onSignInSucceed();
                    } else {
                      if (window.ReactNativeWebView) {
                        postMessageToMobile({
                          event: "CLOSE_WEBVIEW",
                        });
                      }
                      router.push(`/${getLangLocale()}/member/profile`);
                    }
                  },
                })
              );
              if (onCxRedirectCallback) {
                onCxRedirectCallback();
              }
              // router.push(`/${getLangLocale()}/member/link-cx`);
            } else {
              if (onSignInSucceed) {
                onSignInSucceed();
              } else {
                if (window.ReactNativeWebView) {
                  postMessageToMobile({
                    event: "CLOSE_WEBVIEW",
                  });
                }
                router.push(`/${getLangLocale()}/member/profile`);
              }
            }
            setIsLoading(false);
            return;
          } else {
            showTechnicalErrorMsgSignIn();
          }
          setIsLoading(false);
        } catch (err: any) {
          // console.error(err);
          if (err.metadata.status_code === 400 || err.metadata.status_code === 401) {
            if (err.error_code === "NOT_AUTHORIZED" || err.error_code === "USER_DOESNT_EXIST") {
              //setSignInErrorMsg(t("NOT_AUTHORIZED.desc"));
              notAuthorizedModalHandle?.open();
            } else if (
              err.error_code === "SERVER_BUSY" ||
              err.error_code === "FOUND_USER_ACCOUNT_WITH_UNVERIFIED_EMAIL" ||
              err.error_code === "FOUND_USER_ACCOUNT_WITH_UNVERIFIED_PHONE_NUMBER"
            ) {
              showTechnicalErrorMsgSignIn(err.error_code);
            } else {
              showTechnicalErrorMsgSignIn();
            }
          } else {
            showTechnicalErrorMsgSignIn();
          }
          setIsLoading(false);
        }
      }
    },
    [
      setIsLoading,
      onSignInSucceed,
      showTechnicalErrorMsgSignIn,
      dispatch,
      from,
      router,
      notAuthorizedModalHandle,
      b2bIsLogin,
      t,
      onCxRedirectCallback,
      cxAuthenticationRedirectLink,
    ]
  );

  const onForgetPassword = useCallback(
    async (formValues: any) => {
      if (formValues && formValues.data) {
        setIsLoading(true);
        const {
          method,
          email,
          mobile: { countryCode, mobileNumber },
        } = formValues.data;
        if (!method || (method === "email" && !email) || (method === "phone" && (!countryCode || !mobileNumber))) {
          setIsLoading(false);
          showTechnicalErrorMsgForgetPwd();
          return false;
        }
        try {
          const response = await postApi(
            `${memberAuthEndPoints.forgotPassword}?${new URLSearchParams({ lang: getLangLocale() }).toString()}`,
            {
              username: method === "email" ? email : `+${countryCode}${mobileNumber}`,
              username_type: method === "email" ? "EMAIL" : "PHONE",
            },
            undefined,
            undefined,
            true
          );
          if (response.metadata.status_code === 200) {
            forgetPwdSucceedModal?.open();
            onForgetPasswordSucceed && onForgetPasswordSucceed();
          } else {
            showTechnicalErrorMsgForgetPwd();
          }
          setIsLoading(false);
        } catch (err: any) {
          // console.error(err);
          if (err.metadata.status_code === 400 || err.metadata.status_code === 401) {
            if (err.error_code === "NOT_AUTHORIZED" || err.error_code === "USER_DOESNT_EXIST") {
              // setForgetPwdErrorPwdMsgMsg(t("NOT_AUTHORIZED.desc"));
              notAuthorizedModalHandle?.open();
            } else {
              showTechnicalErrorMsgForgetPwd();
            }
          } else {
            showTechnicalErrorMsgForgetPwd();
          }
          setIsLoading(false);
        }
        setIsLoading(false);
      }
    },
    [
      setIsLoading,
      showTechnicalErrorMsgForgetPwd,
      onForgetPasswordSucceed,
      notAuthorizedModalHandle,
      forgetPwdSucceedModal,
    ]
  );

  const handleCxMemberClick = useCallback(() => {
    onCXMemberClick ? onCXMemberClick() : signInCx("/");
  }, [onCXMemberClick]);
  const handleAppleMemberClick = useCallback(() => {
    signInApple("/");
  }, []);
  const COUNTRY_CODE = useCountryCodeOptions();

  const i18nContent = useMemo(
    () => ({
      title: t("web.member.signIn.title"),
      desc: t("web.member.signIn.desc"),
      method: {
        options: [
          {
            value: "email",
            label: t("web.member.signIn.method.email"),
          },
          {
            value: "phone",
            label: t("web.member.signIn.method.number"),
          },
        ],
      },
      emailField: {
        helperText: t("web.member.signIn.emailField.helperText") + "*",
        placeholder: t("web.member.signIn.method.email"),
        required: t("web.flightBook.contactsEmail") as string,
      },
      mobilePhoneField: {
        helperText: t("web.member.signIn.mobilePhoneField.helperText") + "*",
        countryCode: {
          // placeholder: t("web.member.signIn.mobilePhoneField.countryCode.placeholder"),
          required: t("web.flightBook.detail.courtesy.required") as string,
        },
        placeholder: t("web.flightBook.detail.mobile"),
        required: t("web.flightBook.detail.mobile.required") as string,
        options: COUNTRY_CODE,
      },
      passwordField: {
        helperText: t("web.member.signIn.passwordField.helperText") + "*",
        placeholder: t("web.login.password.placeholder"),
        required: t("web.member.signIn.passwordField.required") as string,
      },
      fwd: t("web.B2B.Login.forget.paw"),
      submit: t("web.member.signIn.submit"),
      text: t("web.member.signIn.text"),
      link: t("web.member.signIn.link"),
      other: t("web.member.signIn.other"),
      cxText: t("web.member.signIn.cxText"),
      appleText: t("web.member.signIn.appleText"),
      findBooking: {
        bookingReference: t("web.member.signIn.findBooking.bookingReference"),
        firstName: t("web.member.signIn.findBooking.firstName"),
        lastName: t("web.member.signIn.findBooking.lastName"),
        bookingReferenceRequired: t("web.member.signIn.findBooking.bookingReference.required"),
        firstNameRequired: t("web.member.signIn.findBooking.firstName.required"),
        lastNameRequired: t("web.member.signIn.findBooking.lastName.required"),
        bookingReferenceInvalid: t("web.member.signIn.findBooking.bookingReference.invalid"),
        firstNameInvalid: t("web.member.signIn.findBooking.firstName.invalid"),
        lastNameInvalid: t("web.member.signIn.findBooking.lastName.invalid"),
        retrieveBooking: t("web.member.signIn.findBooking.retrieveBooking"),
        findBookingText: t("web.member.signIn.findBooking.text"),
        following: t("web.member.signIn.findBooking.following"),
        baggages: t("web.member.signIn.findBooking.baggages"),
        changeFlight: t("web.member.signIn.findBooking.changeFlight"),
        meals: t("web.member.signIn.findBooking.meals"),
        cancelFlight: t("web.member.signIn.findBooking.cancelFlight"),
        customerDetails: t("web.member.signIn.findBooking.customerDetails"),
        seatSelection: t("web.member.signIn.findBooking.seatSelection"),
        title: t("web.member.signIn.findBooking.title"),
      },
    }),
    [t, COUNTRY_CODE]
  );

  const i18nContentPassword = useMemo(
    () => ({
      title: t("web.member.forgotPassword.title") as string,
      text: t("web.member.forgotPassword.text") as string,
      submit: t("web.member.login.forgetPassword.text") as string,
      cxText: t("web.member.forgotPassword.cxText") as string,
      appleText: t("web.member.forgotPassword.appleText") as string,
      backToSignInText: t("web.member.forgotPassword.backTo") as string,
      method: {
        options: [
          {
            value: "email",
            label: t("web.member.signIn.method.email") as string,
          },
          {
            value: "phone",
            label: t("web.member.signIn.method.number") as string,
          },
        ],
      },
      emailField: {
        helperText: (t("web.member.signIn.emailField.helperText") as string) + "*",
        placeholder: t("web.member.signIn.method.email") as string,
        required: t("web.flightBook.contactsEmail") as string,
      },
      mobilePhoneField: {
        helperText: (t("web.member.signIn.mobilePhoneField.helperText") as string) + "*",
        countryCode: {
          placeholder: t("web.member.signIn.mobilePhoneField.countryCode.placeholder") as string,
          required: t("web.flightBook.detail.courtesy.required") as string,
        },
        mobileNumber: {
          placeholder: t("web.flightBook.detail.mobile" as string),
          required: t("web.flightBook.detail.mobile.required") as string,
        },
        options: COUNTRY_CODE,
      },
    }),
    [t, COUNTRY_CODE]
  );
  const handleFindBookingSubmit = (data: any) => {
    const { BookingRef, FirstName, LastName } = data.data;
    sessionStorage.setItem(
      "tripParams",
      JSON.stringify({
        bookingRef: BookingRef.toUpperCase(),
        firstName: FirstName,
        lastName: LastName,
      })
    );
    const params = new URLSearchParams({
      bookingRef: BookingRef.toUpperCase(),
      firstName: FirstName,
      lastName: LastName,
      isFromRetrieveBooking: "true",
    });
    RouterInstance.push(`/:lang/manage-my-booking/my-trips/${BookingRef.toUpperCase()}?${params}`);
  };
  return (
    <>
      <Box>
        <SignInAndForgetPwd
          signInUrl={`/${getLangLocale()}/member/signup`}
          onSignIn={onSignIn}
          onForgetPassword={onForgetPassword}
          signInErrorMsg={signInErrorMsg}
          forgetPwdErrorPwdMsgMsg={forgetPwdErrorPwdMsgMsg}
          isDisplayClose={isDisplayClose}
          onCloseLoginNow={onCloseLoginNow}
          i18nContent={i18nContent}
          i18nContentPassword={i18nContentPassword}
          handleCxMemberClick={handleCxMemberClick}
          handleAppleMemberClick={handleAppleMemberClick}
          handleFindBookingSubmit={handleFindBookingSubmit}
          isShowTabs={from === SignInFromType.MMB}
        ></SignInAndForgetPwd>
        <LoadingBackdrop isOpen={isLoading} />
      </Box>
      <Modal ref={setNotAuthorizedModalHandle} title={t("web.member.signIn.notAuthorized.title")}>
        <Box sx={{ mt: EPixel.px24 }}>{t("web.member.signIn.notAuthorized.content")}</Box>
        <Box sx={{ mt: EPixel.px24 }}>
          <Button
            fullWidth
            onClick={() => {
              notAuthorizedModalHandle?.close();
            }}
          >
            {t("web.member.signIn.notAuthorized.buttonText")}
          </Button>
        </Box>
      </Modal>
      <Modal title={t("web.membership.signUp.forgetPassword.popup.success.title")} ref={setForgetPwdSucceedModal}>
        <Stack sx={{ marginTop: ESpacing._s }} spacing={ESpacing._s}>
          <Font>{t("web.membership.signUp.forgetPassword.popup.success.content")}</Font>
          <Button
            fullWidth
            onClick={() => {
              forgetPwdSucceedModal?.close();
            }}
          >
            {t("web.membership.signUp.forgetPassword.popup.success.cta")}
          </Button>
        </Stack>
      </Modal>
    </>
  );
}

function holdThisPage() {
  const url = new URL(window.location.href);
  url.searchParams.set("authHold", "true");
  window.history.replaceState(null, "", url.toString());
}
