import {
  ECourtesy,
  EPaxType,
  ETripType,
  IBEPassengerDetail,
  Journey,
  SearchingDetail,
  TripDetail,
} from "@hkexpressairwayslimited/ui";
import { add, multiply } from "lib/common/helper";
import { MMBJourney, ManageMyBookingTripDetail } from "lib/features/manage-my-booking/my-trips/definition";
import luhn from "luhn";
import { getLangLocale } from "modules/common/common/utils/i18n-utils";
import { FlightRouteMapping } from "store/sessionStorage/slices/flightRouteMapping";
import { formateDateFormat2 } from "../flight-select/helper";
import { APICreateOrderRequestBody, APICreateOrderValidationRequestBody, PaymentMethod, SSR } from "./definition";

export const calculateFareItemAmount = (fares: any, count: number, type: string) => {
  if (type === "ADT") {
    const adultFarePrice = fares.adult_fare_details.service_charge.find(
      (charge: any) => charge.type === "FARE"
    )?.amount;
    return !adultFarePrice || adultFarePrice === null ? 0 : multiply(adultFarePrice, count);
  } else if (type === "CHD") {
    const childFarePrice = fares.child_fare_details?.service_charge.find(
      (charge: any) => charge.type === "FARE"
    )?.amount;
    return !childFarePrice || childFarePrice === null ? 0 : multiply(childFarePrice, count);
  } else if (type === "INF") {
    const infantFarePrice = fares.infant_details?.service_charge.find((charge: any) => charge.type === "FARE")?.amount;
    return !infantFarePrice || infantFarePrice === null ? 0 : multiply(infantFarePrice, count);
  } else {
    return 0;
  }
};

export const calculateBundleFeeItemAmount = (fares: any, count: number, type: string) => {
  if (type === "ADT") {
    const adultBundleFee = fares?.bundle_offers?.[0]?.adult_amount;
    return adultBundleFee ? multiply(adultBundleFee, count) : 0;
  } else if (type === "CHD") {
    const childBundleFee = fares?.bundle_offers?.[0]?.child_amount;
    return childBundleFee ? multiply(childBundleFee, count) : 0;
  } else {
    return 0;
  }
};

export const calculateTax = (fares: any, count: number, type: string) => {
  if (type === "ADT") {
    const adultTax = fares.adult_fare_details?.service_charge.reduce((a: number, charge: any) => {
      if (charge.type === "TAX") {
        return add(a, charge.amount);
      }
      return a;
    }, 0);
    return !adultTax || adultTax === null ? 0 : multiply(adultTax, count);
  } else if (type === "CHD") {
    const childTax = fares.child_fare_details?.service_charge.reduce((a: number, charge: any) => {
      if (charge.type === "TAX") {
        return add(a, charge.amount);
      }
      return a;
    }, 0);
    return !childTax || childTax === null ? 0 : multiply(childTax, count);
  } else if (type === "INF") {
    const infantTax = fares.infant_details?.service_charge.reduce((a: number, charge: any) => {
      if (charge.type === "TAX") {
        return add(a, charge.amount);
      }
      return a;
    }, 0);
    return !infantTax || infantTax === null ? 0 : multiply(infantTax, count);
  } else {
    return 0;
  }
};

const calculateSurcharge = (fares: any, count: number, type: string) => {
  //infant no surcharge
  if (type === "ADT") {
    const adultSurcharge = fares.adult_fare_details?.service_charge.reduce((a: number, charge: any) => {
      if (charge.type === "SURCHARGE") {
        return add(a, charge.amount);
      }

      return a;
    }, 0);
    return !adultSurcharge || adultSurcharge === null ? 0 : multiply(adultSurcharge, count);
  } else if (type === "CHD") {
    const childSurcharge = fares.child_fare_details?.service_charge.reduce((a: number, charge: any) => {
      if (charge.type === "SURCHARGE") {
        return add(a, charge.amount);
      }
      return a;
    }, 0);
    return !childSurcharge || childSurcharge === null ? 0 : multiply(childSurcharge, count);
  } else {
    return 0;
  }
};

const getSSRDetails = (arItems: any, item: any, pax: any) => {
  const tempSSR = arItems.available.find((ssr: any) => ssr?.code === item.code);
  const targetSSR = tempSSR?.ssr_by_passengers?.find((ssr: any) => ssr.passenger_key === pax.paxId);

  if (targetSSR && pax.amount > 0) {
    return {
      ssr_code: item.code,
      ssr_key: targetSSR?.ssr_key,
      item_amount: multiply(pax.amount, targetSSR?.price),
      surcharge: 0,
      tax: 0,
      quantity: pax.amount,
    };
  }
};

const getAllSSR = (tripDetail: TripDetail | ManageMyBookingTripDetail) => {
  const tripSSR = tripDetail.arItems.selected
    .flatMap((item) => {
      if (item.byPax) {
        return item.byPax.map((pax) => getSSRDetails(tripDetail.arItems, item, pax));
      } else return [];
    })
    .filter(Boolean);

  const JourneySegmentSSR = tripDetail.journeys.flatMap((journey) => {
    const journeySSR = journey.arItems.selected
      .flatMap((item) => {
        if (item.byPax) {
          return item.byPax.map((pax) => getSSRDetails(journey.arItems, item, pax));
        } else return [];
      })
      .filter(Boolean);

    const segmentSSR = journey.segments
      .flatMap((segment) => {
        return segment.arItems.selected.flatMap((item) => {
          if (item.byPax && item.type !== "SEAT") {
            return item.byPax.map((pax) => getSSRDetails(segment.arItems, item, pax));
          } else return [];
        });
      })
      .filter(Boolean);

    return [...journeySSR, ...segmentSSR].flat();
  });

  return [...tripSSR, ...JourneySegmentSSR].filter((ssr): ssr is SSR => ssr !== undefined);
};

const getSeats = (paxId: string, tripDetail: TripDetail | ManageMyBookingTripDetail) => {
  const seats = tripDetail.journeys.flatMap((journey) => {
    return journey.segments.flatMap((segment) => {
      return segment.arItems.selected.flatMap((item) => {
        if (item.byPax && item.type === "SEAT" && item.byPax[0].paxId === paxId) {
          return {
            unit_key: item.unit_key ?? "",
            unit_designator: item.code,
            item_amount: item.price ?? 0,
            spoilage: item.spoilage ?? 0,
            journey_key: journey.journey_key,
            segment_key: segment.segment_key,
            byPax: [item.byPax[0]],
          };
        } else return [];
      });
    });
  });
  return seats;
};

const getJourneySeats = (paxId: string, journey: Journey | MMBJourney) => {
  const seats = journey.segments.flatMap((segment) => {
    return segment.arItems.selected.flatMap((item) => {
      if (item.byPax && item.type === "SEAT" && item.byPax[0].paxId === paxId) {
        return {
          unit_key: item.unit_key ?? "",
          unit_designator: item.code,
          item_amount: item.price ?? 0,
        };
      } else return [];
    });
  });
  return seats;
};

const getPurchasedSeats = (journeyKey?: string, booking?: ManageMyBookingTripDetail) => {
  const targetJourney = booking?.journeys.find((journey) => journey.journey_key === journeyKey);
  if (!targetJourney) return [];
  const seats = targetJourney.segments.flatMap((segment) => {
    return segment.arItems.purchased.flatMap((item) => {
      if (item.byPax && item.type === "SEAT") {
        return { ...item, segment_key: segment.segment_key };
      } else return [];
    });
  });
  return seats;
};

const constructOrderValidationRequestBody = ({
  appCode,
  tripDetail,
  passengerDetail,
  searchingDetail,
  selectedCurrency,
  sales_port_grouping,
}: {
  appCode: string;
  sales_port_grouping: FlightRouteMapping["sales_port_grouping"];
  passengerDetail: IBEPassengerDetail;
  tripDetail: TripDetail;
  searchingDetail: SearchingDetail;
  selectedCurrency: string;
}): APICreateOrderValidationRequestBody => {
  const ssrs = getAllSSR(tripDetail);

  const infant = passengerDetail.passengers.filter((passenger) => passenger.paxType === EPaxType.Infant);

  return {
    promotion_code: searchingDetail.promoCode,
    market:
      sales_port_grouping.find((route: any) =>
        route.ports.some((port: any) => port.airport_code === tripDetail.journeys[0].origin)
      )?.market ?? "",
    trip_type:
      searchingDetail.tripType === ETripType.oneWay
        ? "OW"
        : searchingDetail.tripType === ETripType.multiCity
          ? "COMPLEX"
          : "RT",
    selected_currency_code: selectedCurrency,
    currency_code: tripDetail.journeys[0].fare.currency_code,
    customer_country: passengerDetail.contactsMobile.country ?? "",
    infant_total_amount: tripDetail.journeys.reduce((acc, journey) => {
      return add(
        acc,
        calculateFareItemAmount(journey.fare, searchingDetail.passenger_count.Infant, "INF"),
        calculateTax(journey.fare, searchingDetail.passenger_count.Infant, "INF")
      );
    }, 0),
    application_code: searchingDetail.campaignCode ?? appCode,
    journeys: tripDetail.journeys.map((journey, index) => ({
      origin_country:
        sales_port_grouping.find((route: any) =>
          route.ports.some((port: any) => port.airport_code === tripDetail.journeys[index].origin)
        )?.market ?? "",
      destination_country:
        sales_port_grouping.find((route: any) =>
          route.ports.some((port: any) => port.airport_code === tripDetail.journeys[index].destination)
        )?.market ?? "",
      origin_port: journey.segments[0].origin,
      destination_port: journey.segments[journey.segments.length - 1].destination,
      fare_type: journey.fare.bundle_offers[0].type,
      flight_numbers: journey.segments.map((segment) => segment.flight_number),
      departure_date_time: journey.segments[0].departure,
      arrival_date_time: journey.segments[journey.segments.length - 1].arrival,
      journey_key: journey.journey_key,
      fare_availability_key: journey.fare.fare_availability_key ?? "",
      item_amount: add(
        calculateFareItemAmount(journey.fare, searchingDetail.passenger_count.Adult, "ADT"),
        calculateFareItemAmount(journey.fare, searchingDetail.passenger_count.Child, "CHD")
      ),
      surcharge: add(
        calculateSurcharge(journey.fare, searchingDetail.passenger_count.Adult, "ADT"),
        calculateSurcharge(journey.fare, searchingDetail.passenger_count.Child, "CHD")
      ),
      tax: add(
        calculateTax(journey.fare, searchingDetail.passenger_count.Adult, "ADT"),
        calculateTax(journey.fare, searchingDetail.passenger_count.Child, "CHD")
      ),
      is_first_journey: index === 0,
      bundle: {
        bundle_code: journey.fare.bundle_offers[0].bundle_code,
        adult_price: multiply(journey.fare.bundle_offers[0].adult_amount, searchingDetail.passenger_count.Adult),
        child_price: multiply(journey.fare.bundle_offers[0].child_amount, searchingDetail.passenger_count.Child),
      },
    })),
    passengers: passengerDetail.passengers
      .filter((passenger) => passenger.paxType !== EPaxType.Infant)
      .map((passenger, index) => ({
        title: passenger.courtesy ? passenger.courtesy.toUpperCase() : "",
        first_name: passenger.givenName,
        last_name: passenger.surname,
        date_of_birth: formateDateFormat2(passenger.DOB),
        gender: passenger.courtesy?.toUpperCase()
          ? passenger.courtesy === ECourtesy.Mr || passenger.courtesy === ECourtesy.Master
            ? "MALE"
            : "FEMALE"
          : "",
        passport: "",
        passenger_key: passenger.id,
        passenger_type: passenger.paxType === EPaxType.Adult ? "ADT" : "CHD",
        seats: getSeats(passenger.id, tripDetail),
        ...(passenger.docType &&
        passenger.docIssuingCountry &&
        passenger.docNationality &&
        passenger.docNumber &&
        passenger.docExpirationDate
          ? {
              travel_doc: {
                doc_type: passenger.docType,
                issuing_country: passenger.docIssuingCountry,
                doc_nationality: passenger.docNationality,
                doc_number: passenger.docNumber,
                expire_date: formateDateFormat2(passenger.docExpirationDate),
              },
            }
          : {}),
        infant: infant[index]
          ? {
              title: infant[index]?.courtesy?.toUpperCase() ?? "",
              first_name: infant[index]?.givenName ?? "",
              last_name: infant[index]?.surname ?? "",
              date_of_birth: formateDateFormat2(infant[index]?.DOB) ?? "",
              gender: infant[index]?.courtesy?.toUpperCase()
                ? passenger.courtesy === ECourtesy.Master
                  ? "MALE"
                  : "FEMALE"
                : "",

              ...(infant[index]?.docType &&
              infant[index]?.docNumber &&
              infant[index]?.docExpirationDate &&
              infant[index]?.docIssuingCountry &&
              infant[index]?.docNationality
                ? {
                    travel_doc: {
                      doc_type: infant[index].docType,
                      issuing_country: infant[index].docIssuingCountry,
                      doc_nationality: infant[index].docNationality,
                      doc_number: infant[index].docNumber,
                      expire_date: formateDateFormat2(infant[index]?.docExpirationDate ?? ""),
                    },
                  }
                : {}),
            }
          : undefined,
      })),
    contact_person: {
      phone_number: {
        country_code: passengerDetail.contactsMobile.countryCode,
        number: passengerDetail.contactsMobile.mobileNumber,
      },
      first_name: passengerDetail.contactsGivenName,
      last_name: passengerDetail.contactsSurname,
      email_address: passengerDetail.contactsEmail,
      title: passengerDetail.contactsCourtesy.toUpperCase(),
    },

    ssrs: ssrs,
    language: getLangLocale().toUpperCase(),
  };
};

const constructOrderCreationRequestBody = (
  mileAmount: number,
  cash_amount: number,
  infant_total_amount: number,
  currency: string,
  mcp: any,
  passengerDetail: IBEPassengerDetail,
  tripDetail: TripDetail,
  searchingDetail: SearchingDetail,
  // uoUsername: any,
  sales_port_grouping: any[],
  insurance?: number,
  carbon_offset?: number,
  pay_by_organization_credit?: boolean
): APICreateOrderRequestBody => {
  const ssrs = getAllSSR(tripDetail);

  return {
    sales_channel: "ONLINE",
    sales_market:
      sales_port_grouping.find((route: any) =>
        route.ports.some((port: any) => port.airport_code === tripDetail.journeys[0].origin)
      )?.market ?? "",
    customer_country: passengerDetail.contactsMobile.country ?? "",
    sales_port: searchingDetail.trips[0].from,
    infant_total_amount: infant_total_amount,
    mcp,
    currency_code: tripDetail.journeys[0].fare.currency_code,
    cash_amount: cash_amount,
    mile_amount: mileAmount ?? 0,
    selected_currency_code: currency,
    // application_code: "GBS",
    promotion_code: searchingDetail.promoCode,
    // uo_username: uoUsername,
    trip_type:
      searchingDetail.tripType === ETripType.oneWay
        ? "OW"
        : searchingDetail.tripType === ETripType.multiCity
          ? "MC"
          : "RT",
    with_sms: passengerDetail.sendSMSitinerary ?? false,
    is_subscribed_to_email: passengerDetail?.receiveMarketingAndPromotional ?? false,
    journeys: tripDetail.journeys.map((journey, index) => ({
      origin_country:
        sales_port_grouping.find((route: any) =>
          route.ports.some((port: any) => port.airport_code === tripDetail.journeys[index].origin)
        )?.market ?? "",
      destination_country:
        sales_port_grouping.find((route: any) =>
          route.ports.some((port: any) => port.airport_code === tripDetail.journeys[index].destination)
        )?.market ?? "",
      origin_port: journey.segments[0].origin,
      destination_port: journey.segments[journey.segments.length - 1].destination,
      fare_type: journey.fare.bundle_offers[0].type,
      flight_numbers: journey.segments.map((segment) => segment.flight_number),
      departure_date_time: journey.segments[0].departure,
      arrival_date_time: journey.segments[journey.segments.length - 1].arrival,
      journey_key: journey.journey_key,
      fare_availability_key: journey.fare.fare_availability_key,
      item_amount: add(
        calculateFareItemAmount(journey.fare, searchingDetail.passenger_count.Adult, "ADT") ?? 0,
        calculateFareItemAmount(journey.fare, searchingDetail.passenger_count.Child, "CHD")
      ),
      surcharge: add(
        calculateSurcharge(journey.fare, searchingDetail.passenger_count.Adult, "ADT"),
        calculateSurcharge(journey.fare, searchingDetail.passenger_count.Child, "CHD")
      ),
      tax: add(
        calculateTax(journey.fare, searchingDetail.passenger_count.Adult, "ADT"),
        calculateTax(journey.fare, searchingDetail.passenger_count.Child, "CHD")
      ),
      is_first_journey: index === 0,
      bundle: {
        bundle_code: journey.fare.bundle_offers[0].bundle_code,
        adult_price: multiply(journey.fare.bundle_offers[0].adult_amount, searchingDetail.passenger_count.Adult),
        child_price: multiply(journey.fare.bundle_offers[0].child_amount, searchingDetail.passenger_count.Child),
      },
    })),
    passengers: passengerDetail.passengers
      .filter((passenger) => passenger.paxType !== EPaxType.Infant)
      .map((passenger) => ({
        title: passenger.courtesy ? passenger.courtesy.toUpperCase() : "",
        first_name: passenger.givenName,
        last_name: passenger.surname,
        date_of_birth: formateDateFormat2(passenger.DOB),
        gender: passenger.courtesy?.toUpperCase()
          ? passenger.courtesy === ECourtesy.Mr || passenger.courtesy === ECourtesy.Master
            ? "MALE"
            : "FEMALE"
          : "",
        passport: "",
        passenger_key: passenger.id,
        passenger_type: passenger.paxType === EPaxType.Adult ? "ADT" : "CHD",
        seats: getSeats(passenger.id, tripDetail),
      })),
    ssrs: ssrs,
    language: getLangLocale().toUpperCase(),
    contact_person: {
      phone_number: {
        country_code: passengerDetail.contactsMobile.countryCode,
        number: passengerDetail.contactsMobile.mobileNumber,
      },
      first_name: passengerDetail.contactsGivenName,
      last_name: passengerDetail.contactsSurname,
      email_address: passengerDetail.contactsEmail,
      title: passengerDetail.contactsCourtesy.toUpperCase(),
    },
    pay_by_organization_credit: pay_by_organization_credit,
    insurance: insurance
      ? {
          item_amount: insurance,
        }
      : null,
    carbon_offset: carbon_offset
      ? {
          item_amount: carbon_offset,
        }
      : null,
  };
};

export const CC_PAYMENT_METHOD: PaymentMethod[] = [
  PaymentMethod.Amex,
  PaymentMethod.JCB,
  PaymentMethod.Visa,
  PaymentMethod.MasterCard,
];
export function isCCMethod(method?: PaymentMethod): boolean {
  if (!method) return false;
  return CC_PAYMENT_METHOD.includes(method);
}
export function creditCardType(cardNo: string | number): PaymentMethod | undefined {
  const cc = cardNo + "";
  const visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
  const mastercard = new RegExp("^5[1-5][0-9]{14}$");
  const mastercard2 = new RegExp("^2[2-7][0-9]{14}$");
  const amex = new RegExp("^3[47][0-9]{13}$");
  const jcb = new RegExp("^3[35][0-9]{14}[0-9]*$");
  // let cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
  // let cup2 = new RegExp('^81[0-9]{14}[0-9]*$');
  // let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
  // let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
  // let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');
  // let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');

  if (visa.test(cc)) {
    return PaymentMethod.Visa;
  }
  if (mastercard.test(cc) || mastercard2.test(cc)) {
    return PaymentMethod.MasterCard;
  }
  if (amex.test(cc)) {
    return PaymentMethod.Amex;
  }
  if (jcb.test(cc)) {
    return PaymentMethod.JCB;
  }
  // if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
  //   return 'DISCOVER';
  // }
  // if (diners.test(cc)) {
  //   return 'DINERS';
  // }
  // if (cup1.test(cc) || cup2.test(cc)) {
  //   return 'CHINA_UNION_PAY';
  // }
  return undefined;
}
export function validCCNo(cardNo: string | number) {
  return luhn.validate(cardNo + "");
}

export {
  calculateSurcharge,
  constructOrderCreationRequestBody,
  constructOrderValidationRequestBody,
  getAllSSR,
  getJourneySeats,
  getPurchasedSeats,
  getSeats,
};
